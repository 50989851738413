import { Delete, Save, ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { getDoc, doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { firestore } from '../../firebase';
import DynamicTable from '../DynamicTable';

const EditEvent = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [contacts, setContacts] = useState([]);
  const [partners, setPartners] = useState([]);
  const [existingContacts, setExistingContacts] = useState([]);
  const [existingPartners, setExistingPartners] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [sessionType, setSessionType] = useState('');
  const [schedules, setSchedules] = useState([]);
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [snackbarDuration, setSnackbarDuration] = useState(5000);

  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [headerImage, setHeaderImage] = useState(null);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(firestore, 'events', id));
        if (eventDoc.exists()) {
          const data = eventDoc.data();
          setTitle(data.title);
          setStartDate(data.startDate.toDate().toISOString().split('T')[0]);
          setEndDate(data.endDate.toDate().toISOString().split('T')[0]);
          setDescription(data.description);
          setHeaderImageUrl(data.headerImageUrl);
          setLogoUrl(data.logoUrl);
          setSelectedContacts(data.contacts.map(contact => contact.id));
          setSelectedPartners(data.partners.map(partner => partner.id));
          setSessionType(data.sessionType);
          setSchedules(data.schedules ? JSON.parse(data.schedules) : []);
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        setErrorMessage("Failed to load event data. Please try again.");
        setOpenSnackbar(true);
      }
    };

    const fetchExistingContacts = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'contacts'));
      setExistingContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    const fetchExistingPartners = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'partners'));
      setExistingPartners(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchEvent();
    fetchExistingContacts();
    fetchExistingPartners();
  }, [id]);

  const handleFileChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      setErrorMessage('Invalid file type. Please select an image.');
      setOpenSnackbar(true);
    }
  };

  const handleImageUpload = async (image, folder, setImageUrl, editorRef = null) => {
    if (!image) return '';
    const storage = getStorage();
    const uniqueName = `${Date.now()}-${image.name}`;
    const storageRef = ref(storage, `${folder}/${uniqueName}`);
    let blob;
    if (editorRef) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      blob = await (await fetch(canvas)).blob();
    } else {
      blob = image;
    }
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    setImageUrl(url);
    return url;
  };

  const validateForm = () => {
    let formErrors = {};
    if (!title) formErrors.title = 'Title is required';
    if (!startDate) formErrors.startDate = 'Start date is required';
    if (!endDate) formErrors.endDate = 'End date is required';
    if (new Date(startDate) > new Date(endDate)) formErrors.endDate = 'End date cannot be before start date';
    if (!description) formErrors.description = 'Description is required';
    if (!headerImage && !headerImageUrl) formErrors.headerImage = 'Header image is required';
    if (!sessionType) formErrors.sessionType = 'Session type is required';

    setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      setErrorMessage('Please fill in all required fields');
      setOpenSnackbar(true);
    }
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const updatedHeaderImageUrl = headerImage ? await handleImageUpload(headerImage, 'header-images', setHeaderImageUrl) : headerImageUrl;
      const updatedLogoUrl = logo ? await handleImageUpload(logo, 'event-logos', setLogoUrl) : logoUrl;

      await updateDoc(doc(firestore, 'events', id), {
        title,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        headerImageUrl: updatedHeaderImageUrl,
        logoUrl: updatedLogoUrl,
        description,
        contacts: selectedContacts.map(contactId => existingContacts.find(contact => contact.id === contactId)),
        partners: selectedPartners.map(partnerId => existingPartners.find(partner => partner.id === partnerId)),
        sessionType,
        schedules: JSON.stringify(schedules),
      });

      navigate('/events');
    } catch (error) {
      console.error('Error updating event:', error);
      setErrorMessage('Error updating event. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleDiscardChanges = () => {
    setDiscardDialogOpen(true);
  };

  const confirmDiscardChanges = () => {
    setDiscardDialogOpen(false);
    navigate('/events');
  };

  const cancelDiscardChanges = () => {
    setDiscardDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>
      {/* Snackbar for error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={snackbarDuration}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          sx={{ width: '100%' }}
          action={
            <Button color="inherit" size="small" onClick={() => setOpenSnackbar(false)}>
              Close
            </Button>
          }
        >
          {errorMessage} ({Math.ceil(snackbarDuration / 1000)}s)
        </Alert>
      </Snackbar>
  
      {/* Confirmation dialog for discarding changes */}
      <Dialog
        open={discardDialogOpen}
        onClose={cancelDiscardChanges}
        aria-labelledby="discard-dialog-title"
      >
        <DialogTitle id="discard-dialog-title">Discard Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to discard all changes? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDiscardChanges} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDiscardChanges} color="secondary">
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
  
      {/* Edit Event Form */}
      <form onSubmit={handleSubmit}>
        {/* Event Title */}
        <Box mb={2}>
          <TextField
            label="Event Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
        </Box>
  
        {/* Start Date */}
        <Box mb={2}>
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
        </Box>
  
        {/* End Date */}
        <Box mb={2}>
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
        </Box>
  
        {/* Session Type */}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Session Type</InputLabel>
            <Select
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
              label="Session Type"
            >
              <MenuItem value="regional">Regional</MenuItem>
              <MenuItem value="national">National</MenuItem>
              <MenuItem value="mini">Mini Session</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Box>
  
        {/* Header Image */}
        <Box mb={2}>
          <Typography variant="h6">Upload Header Image</Typography>
          <Button variant="outlined" component="label">
            Upload Image
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(event) => handleFileChange(event, setHeaderImage)}
            />
          </Button>
          {headerImageUrl && (
            <Box mt={2} textAlign="center">
              <img
                src={headerImageUrl}
                alt="Header"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            </Box>
          )}
        </Box>
  
        {/* Logo Image */}
        <Box mb={2}>
          <Typography variant="h6">Upload Logo</Typography>
          <Button variant="outlined" component="label">
            Upload Image
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(event) => handleFileChange(event, setLogo)}
            />
          </Button>
          {logoUrl && (
            <Box mt={2} textAlign="center">
              <img src={logoUrl} alt="Logo" style={{ maxWidth: '100%', maxHeight: '300px' }} />
            </Box>
          )}
        </Box>
  
        {/* Description */}
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Description
          </Typography>
          <ReactQuill
            value={description}
            onChange={setDescription}
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'video',
            ]}
            style={{ minHeight: '200px' }}
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              {errors.description}
            </Typography>
          )}
        </Box>
  
        {/* Schedules */}
        <DynamicTable
          tables={schedules}
          onSave={(updatedSchedules) => setSchedules(updatedSchedules)}
        />
  
        {/* Contacts Selection */}
        <Typography variant="h6" gutterBottom>
          Select Existing Contacts
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Select Contacts</InputLabel>
          <Select
            multiple
            value={selectedContacts}
            onChange={(e) => setSelectedContacts(e.target.value)}
            label="Select Contacts"
          >
            {existingContacts.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <Box>
  {/* Display Selected Contacts */}
  <Typography variant="h6" gutterBottom>
    Contacts
  </Typography>
  {selectedContacts.length > 0 ? (
    selectedContacts.map((contactId) => {
      const contact = existingContacts.find((c) => c.id === contactId);
      if (!contact) return null; // Skip if contact is undefined

      return (
        <Card key={contact.id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Contact</Typography>
            <Avatar src={contact?.imageUrl || ''} sx={{ width: 56, height: 56 }} />
            <Typography>Name: {contact.name}</Typography>
            <Typography>Role: {contact.role}</Typography>
            <Typography>Email: {contact.email}</Typography>
            <Typography>Phone: {contact.phone}</Typography>
            <IconButton
              onClick={() =>
                setSelectedContacts(selectedContacts.filter((id) => id !== contact.id))
              }
            >
              <Delete />
            </IconButton>
          </CardContent>
        </Card>
      );
    })
  ) : (
    <Typography variant="body2" color="textSecondary">
      No contacts selected
    </Typography>
  )}
</Box>

<Box>
  {/* Display Selected Partners */}
  <Typography variant="h6" gutterBottom>
    Partners
  </Typography>
  {selectedPartners.length > 0 ? (
    selectedPartners.map((partnerId) => {
      const partner = existingPartners.find((p) => p.id === partnerId);
      if (!partner) return null; // Skip if partner is undefined

      return (
        <Card key={partner.id} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6">Partner</Typography>
            <Avatar src={partner?.logoUrl || ''} sx={{ width: 56, height: 56 }} />
            <Typography>Name: {partner.name}</Typography>
            <IconButton
              onClick={() =>
                setSelectedPartners(selectedPartners.filter((id) => id !== partner.id))
              }
            >
              <Delete />
            </IconButton>
          </CardContent>
        </Card>
      );
    })
  ) : (
    <Typography variant="body2" color="textSecondary">
      No partners selected
    </Typography>
  )}
</Box>

  
        {/* Save and Discard Buttons */}
        <Box mt={4} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" type="submit">
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleDiscardChanges}>
            Discard Changes
          </Button>
        </Box>
      </form>
    </Container>
  );
};  
export default EditEvent;