import { Button, Card, CardActions, CardContent, Container, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { getAuth } from 'firebase/auth';
import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialogue';
import usePermissionCheck from '../../../services/userPermissionCheck';


const Events = () => {
  const canAccessEvents = usePermissionCheck('manage_events');

  const [events, setEvents] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const db = getFirestore();
      const eventsSnapshot = await getDocs(collection(db, 'events'));
      const eventsData = eventsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEvents(eventsData);
    };

    fetchEvents();
  }, []);

  const handleDelete = async (id) => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const eventDoc = await getDoc(doc(db, 'events', id));
          if (eventDoc.exists()) {
            await setDoc(doc(db, 'backup_events', id), eventDoc.data());
            await deleteDoc(doc(db, 'events', id));
            setEvents(events.filter((event) => event.id !== id));
          }
    } else {
      console.error('No user is signed in');
    }
  };

  const handleOpenDialog = (id) => {
    setEventToDelete(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEventToDelete(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (eventToDelete) {
      handleDelete(eventToDelete);
      handleCloseDialog();
    }
  };

  const toggleDescription = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  return (
    <div>
      {canAccessEvents ? (
      
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Events
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/create-event')}
        sx={{ mb: 2 }}
      >
        Create New Event
      </Button>
      <Grid container spacing={3}>
        {events.map((event) => (
          <Grid item xs={12} sm={12} md={6} key={event.id}>
            <Card sx={{ position: 'relative', minHeight: 200 }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {event.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(event.startDate.toDate()).toLocaleDateString()} - {new Date(event.endDate.toDate()).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, mb: 1 }}
                  dangerouslySetInnerHTML={{
                    __html: expandedEventId === event.id
                      ? event.description
                      : `${event.description.substring(0, 100)}...`,
                  }}
                />
                {event.description.length > 100 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => toggleDescription(event.id)}
                  >
                    {expandedEventId === event.id ? 'Show Less' : 'Read More'}
                  </Button>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => navigate(`/edit-event/${event.id}`)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    color="secondary"
                    onClick={() => handleOpenDialog(event.id)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        title="Confirm Deletion"
        content="Are you sure you want to delete this event? This action cannot be undone."
      />
    </Container>): (
        <Typography variant="body1" color="error">You do not have permission to manage events.</Typography>
      )}
    </div>
  );
};

export default Events;
