import { Delete, Save, ZoomIn, ZoomOut } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { addDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authContext';
import { firestore } from '../../firebase';
import DynamicTable from '../DynamicTable';

const CreateEventForm = () => {
  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [description, setDescription] = useState('');
  const [contacts, setContacts] = useState([]);
  const [partners, setPartners] = useState([]);
  const [existingContacts, setExistingContacts] = useState([]);
  const [existingPartners, setExistingPartners] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [contactImage, setContactImage] = useState('');
  const [ setContactImageUrl] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [partnerLogo, setPartnerLogo] = useState('');
  const [ setPartnerLogoUrl] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [zoom, setZoom] = useState(1);
  const [sessionType, setSessionType] = useState('');
  const [role, setRole] = useState('');
  const [logo, setLogo] = useState(null);
  const [  logoUrl, setLogoUrl] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [schedules, setSchedules] = useState([]);



  const editorRef = useRef(null);
  const { userRoles } = useAuth();
  const navigate = useNavigate();
  const [headerImage, setHeaderImage] = useState(null);
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchExistingContacts = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'contacts'));
      const contactsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExistingContacts(contactsData);
    };

    const fetchExistingPartners = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'partners'));
      const partnersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setExistingPartners(partnersData);
    };

    fetchExistingContacts();
    fetchExistingPartners();
  }, []);

  const generateUniqueFilename = (originalName) => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 12);
    const extension = originalName.split('.').pop();
    return `${timestamp}-${randomString}.${extension}`;
  };

  const handleFileChange = (event, setImage) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImage(file);
    } else {
      setOpenSnackbar(true);
      setErrorMessage('Invalid file type. Please select an image.');
    }
  };

  const handleImageUpload = async (image, folder, setImageUrl, editorRef = null) => {
    if (!image) return '';
    const storage = getStorage();
    const uniqueName = generateUniqueFilename(image.name);
    const storageRef = ref(storage, `${folder}/${uniqueName}`);
    let blob;
    if (editorRef) {
      const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
      blob = await (await fetch(canvas)).blob();
    } else {
      blob = image;
    }
    await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(storageRef);
    setImageUrl(url);
    return url;
  };

  const handleDeleteImage = (setImage) => {
    setImage(null);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!title) formErrors.title = 'Title is required';
    if (!startDate) formErrors.startDate = 'Start date is required';
    if (!endDate) formErrors.endDate = 'End date is required';
    if (new Date(startDate) > new Date(endDate)) formErrors.endDate = 'End date cannot be before start date';
    if (!description) formErrors.description = 'Description is required';
    if (!headerImage && !headerImageUrl) formErrors.headerImage = 'Header image is required';
    if (!sessionType) formErrors.sessionType = 'Session type is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const serializedSchedules = JSON.stringify(schedules); // Convert schedules array to JSON string

      const headerUrl = headerImage ? await handleImageUpload(headerImage, 'header-images', setHeaderImageUrl) : headerImageUrl;
      // eslint-disable-next-line#
      
      const logoUrl = logo ? await handleImageUpload(logo, 'event-logos', setLogoUrl) : logoUrl;
      await addDoc(collection(firestore, 'events'), {
        title,
        startDate: Timestamp.fromDate(new Date(startDate)),
        endDate: Timestamp.fromDate(new Date(endDate)),
        headerImageUrl: headerUrl,
        logoUrl: logoUrl || '',
        description,
        contacts: [...contacts, ...selectedContacts.map(id => existingContacts.find(contact => contact.id === id))],
        partners: [...partners, ...selectedPartners.map(id => existingPartners.find(partner => partner.id === id))],
        sessionType,
        schedules: serializedSchedules, // Store the schedules as a JSON string
      });
      navigate('/events');
    } catch (error) {
      console.error('Error creating event:', error);
      setOpenSnackbar(true);
      setErrorMessage('Error creating event. Please try again.');
    }
  };

  const handleAddContact = async () => {
    try {
      const contactUrl = await handleImageUpload(contactImage, 'contact-images', setContactImageUrl, editorRef);
      const newContact = { name: contactName, role: role, email: contactEmail, phone: contactPhone, imageUrl: contactUrl };
      const docRef = await addDoc(collection(firestore, 'contacts'), newContact);
      setContacts([...contacts, { ...newContact, id: docRef.id }]);
      setShowContactForm(false);
      setContactName('');
      setRole('');
      setContactEmail('');
      setContactPhone('');
      setContactImage(null);
    } catch (error) {
      console.error('Error adding contact:', error);
      setOpenSnackbar(true);
      setErrorMessage('Error adding contact. Please try again.');
    }
  };

  const handleAddPartner = async () => {
    try {
      const partnerUrl = await handleImageUpload(partnerLogo, 'partner-logos', setPartnerLogoUrl, editorRef);
      const newPartner = { name: partnerName, logoUrl: partnerUrl };
      const docRef = await addDoc(collection(firestore, 'partners'), newPartner);
      setPartners([...partners, { ...newPartner, id: docRef.id }]);
      setShowPartnerForm(false);
      setPartnerName('');
      setPartnerLogo(null);
    } catch (error) {
      console.error('Error adding partner:', error);
      setOpenSnackbar(true);
      setErrorMessage('Error adding partner. Please try again.');
    }
  };

  const handleRemoveSelectedContact = (id) => {
    setSelectedContacts(selectedContacts.filter(contactId => contactId !== id));
  };

  const handleRemoveSelectedPartner = (id) => {
    setSelectedPartners(selectedPartners.filter(partnerId => partnerId !== id));
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSaveSchedules = (tables) => {
    setSchedules(tables); 
  };



  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Create Event
      </Typography>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Event Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Start Date"
            type="date"
            variant="outlined"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.endDate}
            helperText={errors.endDate}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Session Type</InputLabel>
            <Select
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
              label="Session Type"
            >
              <MenuItem value="regional">Regional</MenuItem>
              <MenuItem value="national">National</MenuItem>
              <MenuItem value="mini">Mini Session</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload Header Image</Typography>
          <Button variant="outlined" component="label">
            Upload Image
            <input type="file" accept="image/*" hidden onChange={(event) => handleFileChange(event, setHeaderImage)} />
          </Button>
          {headerImage && (
            <Box mt={2} textAlign="center">
              <img src={URL.createObjectURL(headerImage)} alt="Header Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              <IconButton onClick={() => handleDeleteImage(setHeaderImage)}>
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box mb={2}>
          <Typography variant="h6">Upload Logo</Typography>
          <Button variant="outlined" component="label">
            Upload Image
            <input type="file" accept="image/*" hidden onChange={(event) => handleFileChange(event, setLogo)} />
          </Button>
          {logo && (
            <Box mt={2} textAlign="center">
              <img src={URL.createObjectURL(logo)} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              <IconButton onClick={() => handleDeleteImage(setLogo)}>
                <Delete />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Description
          </Typography>
          <ReactQuill
            value={description}
            onChange={setDescription}
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'video',
            ]}
            style={{ minHeight: '200px' }}
            error={!!errors.description}
          />
          {errors.description && (
            <Typography variant="body2" color="error">
              {errors.description}
            </Typography>
          )}
        </Box>
        <DynamicTable onSave={handleSaveSchedules} />
        <Typography variant="h6" gutterBottom>
          Select Existing Contacts
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Select Contacts</InputLabel>
          <Select
            multiple
            value={selectedContacts}
            onChange={(e) => setSelectedContacts(e.target.value)}
            label="Select Contacts"
          >
            {existingContacts.map((contact) => (
              <MenuItem key={contact.id} value={contact.id}>
                {contact.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" gutterBottom>
          Contacts
        </Typography>
        <Box>
          {contacts.map((contact, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">Contact {index + 1}</Typography>
                <Avatar src={contact.imageUrl} sx={{ width: 56, height: 56 }} />
                <Typography>Name: {contact.name}</Typography>
                <Typography>Role: {contact.role}</Typography>
                <Typography>Email: {contact.email}</Typography>
                <Typography>Phone: {contact.phone}</Typography>
              </CardContent>
            </Card>
          ))}
          {selectedContacts.map((contactId) => {
            const contact = existingContacts.find((contact) => contact.id === contactId);
            return (
              <Card key={contact.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">Contact</Typography>
                  <Avatar src={contact.imageUrl} sx={{ width: 56, height: 56 }} />
                  <Typography>Name: {contact.name}</Typography>
                  <Typography>Role: {contact.role}</Typography>
                  <Typography>Email: {contact.email}</Typography>
                  <Typography>Phone: {contact.phone}</Typography>
                  <IconButton onClick={() => handleRemoveSelectedContact(contact.id)}>
                    <Delete />
                  </IconButton>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        {showContactForm && (
          <Box mt={2}>
            <TextField
              label="Contact Name"
              variant="outlined"
              fullWidth
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Role"
              variant="outlined"
              fullWidth
              value={role}
              onChange={(e) => setRole(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Contact Email"
              variant="outlined"
              fullWidth
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Contact Phone"
              variant="outlined"
              fullWidth
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Typography variant="h6">Upload Contact Image</Typography>
            <Button variant="outlined" component="label">
              Upload Image
              <input type="file" accept="image/*" hidden onChange={(event) => handleFileChange(event, setContactImage)} />
            </Button>
            {contactImage && (
              <Box mt={2} textAlign="center">
                <AvatarEditor
                  ref={editorRef}
                  image={contactImage}
                  width={150}
                  height={150}
                  border={50}
                  borderRadius={75}
                  scale={zoom}
                  rotate={0}
                  style={{ width: '100%', height: 'auto' }}
                />
                <Box mt={2} display="flex" justifyContent="center">
                  <IconButton onClick={() => setZoom(zoom + 0.1)}>
                    <ZoomIn />
                  </IconButton>
                  <IconButton onClick={() => setZoom(zoom - 0.1)}>
                    <ZoomOut />
                  </IconButton>
                  <IconButton onClick={() => handleImageUpload(contactImage, 'contact-images', setContactImageUrl, editorRef)}>
                    <Save />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteImage(setContactImage)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            )}
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleAddContact}>
                Add Contact
              </Button>
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowContactForm(!showContactForm)}
          >
            {showContactForm ? 'Cancel' : "Don't see the contact card you are looking for? Create a new one here!"}
          </Button>
        </Box>
        <Typography variant="h6" gutterBottom>
          Select Existing Partners
        </Typography>
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel>Select Partners</InputLabel>
          <Select
            multiple
            value={selectedPartners}
            onChange={(e) => setSelectedPartners(e.target.value)}
            label="Select Partners"
          >
            {existingPartners.map((partner) => (
              <MenuItem key={partner.id} value={partner.id}>
                {partner.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" gutterBottom>
          Partners
        </Typography>
        <Box>
          {partners.map((partner, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">Partner {index + 1}</Typography>
                <Avatar src={partner.logoUrl} sx={{ width: 56, height: 56 }} />
                <Typography>Name: {partner.name}</Typography>
              </CardContent>
            </Card>
          ))}
          {selectedPartners.map((partnerId) => {
            const partner = existingPartners.find((partner) => partner.id === partnerId);
            return (
              <Card key={partner.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">Partner</Typography>
                  <Avatar src={partner.logoUrl} sx={{ width: 56, height: 56 }} />
                  <Typography>Name: {partner.name}</Typography>
                  <IconButton onClick={() => handleRemoveSelectedPartner(partner.id)}>
                    <Delete />
                  </IconButton>
                </CardContent>
              </Card>
            );
          })}
        </Box>
        {showPartnerForm && (
          <Box mt={2}>
            <TextField
              label="Partner Name"
              variant="outlined"
              fullWidth
              value={partnerName}
              onChange={(e) => setPartnerName(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Typography variant="h6">Upload Partner Logo</Typography>
            <Button variant="outlined" component="label">
              Upload Image
              <input type="file" accept="image/*" hidden onChange={(event) => handleFileChange(event, setPartnerLogo)} />
            </Button>
            {partnerLogo && (
              <Box mt={2} textAlign="center">
                <AvatarEditor
                  ref={editorRef}
                  image={partnerLogo}
                  width={150}
                  height={150}
                  border={50}
                  borderRadius={75}
                  scale={zoom}
                  rotate={0}
                  style={{ width: '100%', height: 'auto' }}
                />
                <Box mt={2} display="flex" justifyContent="center">
                  <IconButton onClick={() => setZoom(zoom + 0.1)}>
                    <ZoomIn />
                  </IconButton>
                  <IconButton onClick={() => setZoom(zoom - 0.1)}>
                    <ZoomOut />
                  </IconButton>
                  <IconButton onClick={() => handleImageUpload(partnerLogo, 'partner-logos', setPartnerLogoUrl, editorRef)}>
                    <Save />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteImage(setPartnerLogo)}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            )}
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleAddPartner}>
                Add Partner
              </Button>
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowPartnerForm(!showPartnerForm)}
          >
            {showPartnerForm ? 'Cancel' : "Don't see the partner you are looking for? Add a new one here!"}
          </Button>
        </Box>
        <Box mt={4}>
          <Button variant="contained" color="primary" type="submit">
            Create Event
          </Button>
        </Box>
      </form>
    </Container>
  );
};

export default CreateEventForm;
