import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../authContext';
import { firestore } from '../../../firebase';
import usePermissionCheck from '../../../services/userPermissionCheck';

const AdminNews = () => {
  const canAccessNews = usePermissionCheck('manage_news');

  const [newsList, setNewsList] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState(''); // New state for category
  const [currentNewsId, setCurrentNewsId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteNewsId, setDeleteNewsId] = useState(null);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  
  const categories = ['Events', 'Partner', 'Non-EYP', 'Calls']; // Example categories

  useEffect(() => {
    const fetchNews = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'news'));
      const newsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNewsList(newsData);
    };

    fetchNews();
  }, );

  const validateForm = () => {
    if (!title || !content || !category) {
      setError('All fields are required');
      setOpenSnackbar(true);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      if (currentNewsId) {
        const newsRef = doc(firestore, 'news', currentNewsId);
        await updateDoc(newsRef, {
          title,
          content,
          category, // Save selected category
          updatedAt: Timestamp.now(),
        });
        setNewsList(newsList.map(news => news.id === currentNewsId ? { ...news, title, content, category, updatedAt: Timestamp.now() } : news));
      } else {
        const newDoc = await addDoc(collection(firestore, 'news'), {
          title,
          content,
          category, // Save selected category
          createdAt: Timestamp.now(),
        });
        setNewsList([...newsList, { id: newDoc.id, title, content, category, createdAt: Timestamp.now() }]);
      }

      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating news:', err);
      setError('Error creating/updating news. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleEdit = (news) => {
    setTitle(news.title);
    setContent(news.content);
    setCategory(news.category); // Set selected category
    setCurrentNewsId(news.id);
    setOpenDialog(true);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteNewsId(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'news', deleteNewsId));
      setNewsList(newsList.filter(news => news.id !== deleteNewsId));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting news:', err);
      setError('Error deleting news. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDialog = () => {
    setTitle('');
    setContent('');
    setCategory(''); // Reset category
    setCurrentNewsId(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setTitle('');
    setContent('');
    setCategory(''); // Reset category
    setCurrentNewsId(null);
    setOpenDialog(false);
  };



  return (
    <div>
    {canAccessNews ? (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Manage News</Typography>
      <IconButton onClick={handleOpenDialog}>
        <Add fontSize="large" />
      </IconButton>
      <Box sx={{ mt: 2 }}>
        {newsList.map(news => (
          <Card key={news.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6">{news.title}</Typography>
              <Typography variant="body2">{news.content}</Typography>
              <Typography variant="body2" color="textSecondary">Category: {news.category}</Typography> {/* Display category */}
            </CardContent>
            <CardActions>
              <IconButton onClick={() => handleEdit(news)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteDialog(news.id)}>
                <Delete />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{currentNewsId ? 'Edit News' : 'Create News'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Content"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Category"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {categories.map((cat, index) => (
                  <MenuItem key={index} value={cat}>{cat}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button type="submit" color="primary">{currentNewsId ? 'Save' : 'Create'}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this news article?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>): (
        <Typography variant="body1" color="error">You do not have permission to manage events.</Typography>
      )}
    </div>
  );
};

export default AdminNews;
