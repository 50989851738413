import CallIcon from '@mui/icons-material/Call';
import EventIcon from '@mui/icons-material/Event';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const AdminDashboard = () => {
  const navigate = useNavigate();

  const adminItems = [
    { label: 'Events', icon: <EventIcon />, path: '/user-dashboard/admin/events' },
    { label: 'News', icon: <NewspaperIcon />, path: '/user-dashboard/admin/news' },
    { label: 'Quotes', icon: <FormatQuoteIcon />, path: '/user-dashboard/admin/quotes' },
    { label: 'Calls', icon: <CallIcon />, path: '/user-dashboard/admin/calls' },
    { label: 'Contacts', icon: <PersonIcon />, path: '/user-dashboard/admin/contacts' },
    { label: 'Tech Support', icon: <SupportAgentIcon />, path: '/user-dashboard/admin/tickets' }, 
    { label: 'Role Management', icon: <SupportAgentIcon />, path: '/user-dashboard/admin/role-management' }
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>Select an Admin Item to Edit</Typography>
      <Grid container spacing={4}>
        {adminItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {item.icon}
                <Typography variant="h6">{item.label}</Typography>
              </CardContent>
              <Button variant="contained" color="primary" fullWidth onClick={() => navigate(item.path)}>
                Edit {item.label}
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
