import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  Box,
  CardActions,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Alert,
  Breadcrumbs,
  Link,
} from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../authContext';
import { firestore } from '../../../firebase';
import ErrorPage from '../../../components/ErrorPage';
const LoadingAnimation = '/loading_animation.svg';

const AdminCalls = () => {
  const [calls, setCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [form, setForm] = useState({ title: '', description: '', endDate: '', document: null, link: '' });
  const [currentCallId, setCurrentCallId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCallId, setDeleteCallId] = useState(null);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [showOpenOnly, setShowOpenOnly] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expandedCallId, setExpandedCallId] = useState(null);

  useEffect(() => {
    const checkAdminRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userRoles = userDoc.data().roles || [];
          let hasAdminPermission = false;
          for (const roleId of userRoles) {
            const roleDoc = await getDoc(doc(firestore, 'roles', roleId));
            if (roleDoc.exists()) {
              const rolePermissions = roleDoc.data().permissions || [];
              if (rolePermissions.includes("manage_calls") || rolePermissions.includes("admin")) {
                hasAdminPermission = true;
                break;
              }
            }
          }
          setIsAdmin(hasAdminPermission);
        } else {
          setIsAdmin(false);
        }
      }
      setLoading(false);
    };

    const fetchCalls = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'calls'));
      const callsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCalls(callsData);
      setFilteredCalls(callsData);
    };

    checkAdminRole();
    fetchCalls();
  }, [currentUser]);

  const applyFilters = () => {
    let filtered = calls;
    
    if (dateRange.start || dateRange.end) {
      const start = dateRange.start ? new Date(dateRange.start) : null;
      const end = dateRange.end ? new Date(dateRange.end) : null;
      filtered = filtered.filter(call => {
        const callEndDate = call.endDate.toDate();
        return (!start || callEndDate >= start) && (!end || callEndDate <= end);
      });
    }
    
    if (showOpenOnly) {
      const today = new Date();
      filtered = filtered.filter(call => call.endDate.toDate() >= today);
    }
    
    setFilteredCalls(filtered);
  };

  useEffect(applyFilters, [dateRange, showOpenOnly, calls]);

  const handleOpenDateRangeDialog = () => setDateRangeDialogOpen(true);
  const handleCloseDateRangeDialog = () => setDateRangeDialogOpen(false);
  const handleDateRangeChange = (e) => setDateRange(prev => ({ ...prev, [e.target.name]: e.target.value }));
  const toggleShowOpenOnly = () => setShowOpenOnly(prev => !prev);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'calls', id));
      setCalls(calls.filter(call => call.id !== id));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting call:', err);
      setError('Error deleting call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDialog = () => {
    setForm({ title: '', description: '', endDate: '', document: null, link: '' });
    setCurrentCallId(null);
    setOpenDialog(true);
  };

  const handleEdit = (call) => {
    setForm({
      title: call.title,
      description: call.description,
      endDate: call.endDate.toDate().toISOString().split('T')[0],
      document: null,
      link: call.applyLink,
    });
    setCurrentCallId(call.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setForm({ title: '', description: '', endDate: '', document: null, link: '' });
    setCurrentCallId(null);
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let docUrl = '';
      if (form.document) {
        const storage = getStorage();
        const storageRef = ref(storage, `call-documents/${form.document.name}`);
        await uploadBytes(storageRef, form.document);
        docUrl = await getDownloadURL(storageRef);
      }

      const callData = {
        title: form.title,
        description: form.description,
        endDate: Timestamp.fromDate(new Date(form.endDate)),
        applyLink: form.link || docUrl,
      };

      if (currentCallId) {
        await updateDoc(doc(firestore, 'calls', currentCallId), callData);
        setCalls(calls.map(call => (call.id === currentCallId ? { ...call, ...callData } : call)));
      } else {
        const newDoc = await addDoc(collection(firestore, 'calls'), callData);
        setCalls([...calls, { id: newDoc.id, ...callData }]);
      }

      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating call:', err);
      setError('Error creating/updating call. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const toggleDescription = (id) => {
    setExpandedCallId(prevId => (prevId === id ? null : id));
  };

  if (loading) {
    return <img src={LoadingAnimation} alt="Loading" style={{ width: '150px' }} />;
  }

  if (!isAdmin) {
    return <ErrorPage message="You do not have permission to manage calls." />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Manage Calls
      </Typography>

      {/* Breadcrumb-like Filters */}
      <Breadcrumbs separator="|" aria-label="filter breadcrumbs" sx={{ mb: 4, mt: 2 }}>
        <Link color="inherit" onClick={handleOpenDateRangeDialog} style={{ cursor: 'pointer' }}>
          Date Range
        </Link>
        <Link color="inherit" onClick={toggleShowOpenOnly} style={{ cursor: 'pointer' }}>
          {showOpenOnly ? "Show All" : "Open Only"}
        </Link>
      </Breadcrumbs>

      {/* Add Call Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        sx={{ mb: 2 }}
        startIcon={<Add />}
      >
        Create New Call
      </Button>

      <Grid container spacing={3}>
        {filteredCalls.map((call) => (
          <Grid item xs={12} sm={6} md={4} key={call.id}>
            <Card sx={{ minHeight: 250 }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 1, color: 'primary.main' }}>
                  {call.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Ends: {new Date(call.endDate.toDate()).toLocaleDateString()}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ mt: 2, mb: 1 }}
                  dangerouslySetInnerHTML={{
                    __html:
                      expandedCallId === call.id
                        ? call.description
                        : `${call.description.substring(0, 100)}...`,
                  }}
                />
                {call.description.length > 100 && (
                  <Button size="small" color="primary" onClick={() => toggleDescription(call.id)}>
                    {expandedCallId === call.id ? 'Show Less' : 'Read More'}
                  </Button>
                )}
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Edit">
                  <IconButton color="primary" onClick={() => handleEdit(call)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="secondary" onClick={() => { setDeleteDialogOpen(true); setDeleteCallId(call.id); }}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Add/Edit Call Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{currentCallId ? 'Edit Call' : 'Create Call'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Call Title"
              variant="outlined"
              fullWidth
              value={form.title}
              onChange={(e) => setForm({ ...form, title: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={form.description}
              onChange={(e) => setForm({ ...form, description: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              label="End Date"
              type="date"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={form.endDate}
              onChange={(e) => setForm({ ...form, endDate: e.target.value })}
              sx={{ mb: 2 }}
            />
            <Box mb={2}>
              <Button variant="outlined" component="label" sx={{ mr: 2 }}>
                Upload Document
                <input type="file" accept=".pdf,.doc,.docx" hidden onChange={(e) => setForm({ ...form, document: e.target.files[0] })} />
              </Button>
              {form.document && (
                <Typography variant="body1">{form.document.name}</Typography>
              )}
              <TextField
                label="Or Add Link"
                variant="outlined"
                fullWidth
                value={form.link}
                onChange={(e) => setForm({ ...form, link: e.target.value })}
                sx={{ mt: 2 }}
              />
            </Box>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
              <Button type="submit" color="primary">{currentCallId ? 'Save' : 'Create'}</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Date Range Dialog */}
      <Dialog open={dateRangeDialogOpen} onClose={handleCloseDateRangeDialog}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            name="start"
            value={dateRange.start}
            onChange={handleDateRangeChange}
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            name="end"
            value={dateRange.end}
            onChange={handleDateRangeChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDateRangeDialog}>Cancel</Button>
          <Button onClick={() => { applyFilters(); handleCloseDateRangeDialog(); }} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this call?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={() => handleDelete(deleteCallId)} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Error Notification */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminCalls;
