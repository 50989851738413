import { Avatar, Box, Card, CardContent, CardMedia, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const EventDetail = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const eventData = docSnap.data();
        // Deserialize the schedules (since they were stored as a JSON string)
        if (eventData.schedules) {
          eventData.schedules = JSON.parse(eventData.schedules);
        }
        setEvent(eventData);
      } else {
        console.log('No such document!');
      }
    };

    fetchEvent();
  }, [id]);

  if (!event) return <Typography>Loading...</Typography>;

  const sessionTypeDisclaimer = {
    regional: (
      <>
        This is a Regional Session. A Regional Session is a two- or three-day session. Selected participants will be invited to participate in the National Selection Conference. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    national: (
      <>
        This is a National Selection Conference. A National Selection Conference is a three- or four-day session for young participants from all over Austria. Selected Delegates will be invited to represent Austria at International Sessions and other international EYP events. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    mini: (
      <>
        This is a mini session. A Mini Session is a one-day event aiming to spread knowledge and spark engagement. To find out more about such events or the European Youth Parliament in general, please visit <Link to="/about-us">About us</Link>.
      </>
    ),
    other: "",
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  return (
    <Container>
      <Card 
        sx={{
          boxShadow: 0,
        }}
      >
        <CardMedia
          component="img"
          height="300"
          image={event.headerImageUrl}
          alt={event.title}
        />
        {event.logoUrl && (
          <Box
            component="img"
            src={event.logoUrl}
            alt={event.title}
            sx={{
              position: 'relative',
              top: '-100px',
              margin: '0 auto',
              display: 'block',
              height: '200px',
              objectFit: 'contain',
              zIndex: 1
            }}
          />
        )}
      </Card>
      <Box textAlign="center" mt={0} mb={4}>    
        <Typography variant="h3" gutterBottom>{event.title}</Typography>
        <Typography variant="h5" gutterBottom>{formatDate(event.startDate)} - {formatDate(event.endDate)}</Typography>
        {event.sessionType && (
          <Box
            sx={{
              border: '1px solid #ccc',
              padding: '16px',
              borderRadius: '4px',
              backgroundColor: '#f9f9f9',
              display: 'inline-block',
              marginTop: '16px',
              marginBottom: '16px',
            }}
          >
            <Typography variant="body1" color="textSecondary">
              {sessionTypeDisclaimer[event.sessionType]}
            </Typography>
          </Box>
        )}
        <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: event.description }} />
      </Box>

      {/* Schedules Section */}
      {event.schedules && (
        <>
          <Typography variant="h4" gutterBottom>Event Schedules</Typography>
          {event.schedules.map((schedule, index) => (
            <Box key={index} mb={4}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                {schedule.scheduleName}
              </Typography>
              <Paper elevation={3}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {schedule.columns.map((column, colIndex) => (
                        <TableCell key={colIndex} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedule.rows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {schedule.columns.map((column, colIndex) => (
                          <TableCell key={colIndex}>
                            {row[column] || '-'}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Box>
          ))}
        </>
      )}

      {/* Contacts Section */}
      <Typography variant="h4" gutterBottom>People Involved</Typography>
      <Grid container spacing={4} sx={{ mb: 6 }}>
        {event.contacts.map((contact, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 2, borderRadius: 2 }}>
              <CardContent>
                <Box display="flex" alignItems="center">
                  <Avatar 
                    src={contact.imageUrl} 
                    sx={{ width: 80, height: 80, mr: 3 }} 
                    alt={contact.name}
                  />
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{contact.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{contact.role}</Typography>
                    <Typography variant="body2" color="textSecondary">{contact.email}</Typography>
                    <Typography variant="body2" color="textSecondary">{contact.phone}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Partners Section */}
      <Typography variant="h4" gutterBottom>Partners</Typography>
      <Grid container spacing={4}>
        {event.partners.map((partner, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 3, borderRadius: 2, textAlign: 'center' }}>
              <CardContent>
                <Box 
                  component="img"
                  src={partner.logoUrl}
                  alt={partner.name}
                  sx={{
                    height: '150px',
                    maxWidth: '100%',
                    objectFit: 'contain',
                    mb: 2
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{partner.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default EventDetail;
